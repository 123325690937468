export const PITBULL_AVATAR = {
  1: {
    id: 1,
    img: '/assets/images/avatarPitbull/Avatar1.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/0b9695ff4fc57c50061c1a2376a72d5d2ed9247b0bc3dab3a9886bee04d361fd..png'
  },
  2: {
    id: 2,
    img: '/assets/images/avatarPitbull/Avatar2.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/8bdca3ace7ce8db6ec500345014f2de893619d70ac9e313ceb5c3d5c3fef9646..png'
  },
  3: {
    id: 3,
    img: '/assets/images/avatarPitbull/Avatar3.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/d0725150ef18f1caacf5c6774e3ff27e7c1b40d35b00bcf15b62f040452ba5f9..png'
  },
  4: {
    id: 4,
    img: '/assets/images/avatarPitbull/Avatar4.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/d53d2066c6d2c3f73678607cf8d0489db96d3cc0816b63b412272d24d7267a4c..png'
  },
  5: {
    id: 5,
    img: '/assets/images/avatarPitbull/Avatar5.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/dbb093abd46d23256358d255bb33569f0a9985e7ce917eba56de5a05ea8dd25c..png'
  },
  6: {
    id: 6,
    img: '/assets/images/avatarPitbull/Avatar6.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/3f0e7fa8378f22f100b814530eb2e3804e0b20cfc177f75af99b3d61106d0072..png'
  },
  7: {
    id: 7,
    img: '/assets/images/avatarPitbull/Avatar7.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/b91953320cdef2126d10ac5d662e546bcfdb40b764d072fdd9d5f369a9a62f27..png'
  },
  8: {
    id: 8,
    img: '/assets/images/avatarPitbull/Avatar8.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/2e5e1515fd2aa2b13c7a1b626671903d10e05041d0729aa7ba3bc81da2fb2b76..png'
  },
  9: {
    id: 9,
    img: '/assets/images/avatarPitbull/Avatar9.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/054db7eaa8a04a243776f3cf6bdaafd69a67c528493422b676519b8df80aa393..png'
  },
  10: {
    id: 10,
    img: '/assets/images/avatarPitbull/Avatar10.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/b8f637bcb658d2a8e1cd0823921d7a5752483a8afa544ff90edd19be70ba53d8..png'
  },
  11: {
    id: 11,
    img: '/assets/images/avatarPitbull/Avatar11.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/cb0e4ca7bde6f7d23f530b6ef9ef01c81a9dd3f11a1d9441651d722d6428ea58..png'
  },
  12: {
    id: 12,
    img: '/assets/images/avatarPitbull/Avatar12.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/2d790ddb6817296e6877ab60aa0437a1d4e9f29f7c61f560aeaa8be3336c602c..png'
  },
  13: {
    id: 13,
    img: '/assets/images/avatarPitbull/Avatar13.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/9cd9489c7c51711a97bd158463ed1915426b05022ee58ee6d3ec0e3ae51d7987..png'
  },
  14: {
    id: 14,
    img: '/assets/images/avatarPitbull/Avatar14.png',
    path: 'https://noname1412.ap-south-1.linodeobjects.com/PITGAME/20240704/bda9b161d439caad65694da8e72a7abbf2069be8b844f08020f203fb1f3b16b5..png'
  }
}
