import React, { createContext, useContext } from "react";
import ModalLoginHooks from "./hooks";
import { BasicPopupConfirmWrap } from "components/Basic/BasicPopup/BasicPopupConfirmWrap/BasicPopupConfirmWrap";
import useCommonHook from "hooks/useCommonHook";
import LoginComponent from "../Login/login"
export const LoginByModalContext = createContext({
  isOpenModalLogin: false,
  setIsOpenModalLogin: () => { },
});

export const LoginByModalProvider = ({ children }) => {
  const { translate } = useCommonHook();
  const {
    isOpenModalLogin,
    setIsOpenModalLogin
  } = ModalLoginHooks();

  return (
    <LoginByModalContext.Provider value={{ isOpenModalLogin, setIsOpenModalLogin }}>
      {children}
      <BasicPopupConfirmWrap
        isOpen={isOpenModalLogin}
        title={translate('login')}
        onCloseCircle={() => setIsOpenModalLogin(false)}
      >
        <LoginComponent />
      </BasicPopupConfirmWrap>
    </LoginByModalContext.Provider>
  );
}

export const useModalLoginHooks = () => useContext(LoginByModalContext);