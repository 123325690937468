import React from 'react'
import './BasicSegmented.scss'
import { Segmented } from 'antd'

const BasicSegmented = ({
  backgroudColor = 'var(--background_color_component)', // truyền mã màu vào từ variables
  textColor = 'var(--text_color_placeholder)', // truyền mã màu vào từ variables
  activeColor = 'var(--text_color_primary)', // truyền mã màu vào từ variables
  backgroudAciveColor = 'var(--background_color_component)', // truyền mã màu vào từ variables
  block = true,
  disabled = false,
  onChange,
  options,
  value
}) => {
  const styles = `.BasicSegmented{
    .BasicSegmented_background{
      background:${backgroudColor};
    }
    .ant-segmented{
      .ant-segmented-item{
        color:${textColor};
      }
      .ant-segmented-item-selected{
        box-shadow: none;
        color:${activeColor};
      }
      .ant-segmented-thumb, .ant-segmented-item-selected{
        box-shadow: none;
        background:${backgroudAciveColor};
        &::before{
          background: var(--color_success_gradiant);
        }
      }
    }
  }`
  return (
    <div className="BasicSegmented">
      <style>{styles}</style>
      <div className="BasicSegmented_background"></div>
      <Segmented size="large" options={options} value={value} onChange={onChange} disabled={disabled} block={block} />
    </div>
  )
}

export default BasicSegmented
