import { API_GAME_URL } from 'constants/url'
import Request from './request'

export default class WalletService {
  static async withdrawAllGameWallet(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'Wallet/user/withdrawAllGameWallet',
        data,
        newUrl: API_GAME_URL
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
}
