import { IconCamera } from 'assets/icons/iconBasic'
import { IconCrownLine } from 'assets/icons/iconBasic'
import classNames from 'classnames'
import { userFullName } from 'context/UserContext'
import { userLevel } from 'context/UserContext'
import { userAvatar } from 'context/UserContext'
import React from 'react'

export default function UserAvatar({ user, vertical, horizontal, dark, onClick }) {
  return (
    <div
      className={classNames('d-flex', 'align-items-center', 'position-relative', {
        'flex-column': vertical,
        'justify-content-center': vertical
      })} style={{ gap: '8px' }}>
      <div className=" pointer avatar-img" onClick={onClick}>
        <img
          className={classNames('img-rounded', {
            'img-rounded--vertical': vertical,
            pointer: !!onClick
          })}
          src={userAvatar(user)}
          alt=""
          onClick={onClick}
        />
        <div className="select-avatar">
          <IconCamera></IconCamera>
        </div>
      </div>
      <div
        className={classNames('px-2 position-relative', {
          'text-center': vertical
        })}>
        <p
          className={classNames('text-center', {
            'mt-1': vertical,
            h6: vertical,
            'text-light': vertical && !dark,
            'text-dark': dark
          })}
          style={{ fontSize: 'var(--font_size_text_regular)' }}>
          <div className={classNames("d-flex flex-column",{
            'a-center': vertical
          })}>
            <p className="fw-bold">{user?.username}</p>
            <p className="fw-bold text-uppercase"
              style={{
                background: 'var(--background_color_secondary)',
                padding: '0 8px',
                borderRadius: '4px',
                width: 'fit-content',
                border: '1px solid var(--color_primary)',
              }}
            >
              <span style={{
                fontSize: 'var(--font_size_text_small)',
                background: 'linear-gradient(180deg, #FFE7AF 0%, #FEB914 52.6%, #FF8A00 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                textFillColor: 'transparent',
              }}>{user?.appUserMembershipTitle || "VIP"}</span>
            </p>
          </div>
        </p>
      </div>
    </div>
  )
}
