import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import useCommonHook from 'hooks/useCommonHook'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import './SendCode.scss'
import otpService from 'services/otpService'
import LoginService from 'services/loginService'

export const errorOTP = {
  SEND_OTP_FAILED: 'SEND_OTP_FAILED',
  DUPLICATED_USER_EMAIL: 'DUPLICATED_USER_EMAIL',
  NOT_CONFIRMED_YET: 'NOT_CONFIRMED_YET',
}
export default function SendCode(props) {
  const { email, disabled, otpType } = props
  const { translate } = useCommonHook()
  const [otpCountdown, setOtpCountdown] = useState(0)
  const idCountdown = useRef(null)
  const TIME_COUNTDOWN = 300

  function handleSendOTP() {
    if (!email || otpCountdown > 0) return
    LoginService
      .sendOTPEmailToUserAppUser({
        email,
        otpType
      })
      .then((res) => {
        const { isSuccess, error } = res
        if (isSuccess) {
          BasicAlertPopup({
            title: translate('sendOtpSuccess'),
            color: 'success',
            icon: 'success',
            confirmButtonText: translate('confirm')
          })
          setOtpCountdown(TIME_COUNTDOWN)
          return
        } else {
          if (error === "NOT_CONFIRMED_YET") {
            return BasicAlertPopup({
              content: translate('NOT_CONFIRMED_YET'),
              color: 'warning',
              icon: 'warning',
              confirmButtonText: translate('confirm'),
              confirmButtonColor: "primary"
            })
          }
          else if (error === errorOTP?.[error]) {
            BasicAlertPopup({
              title: translate(`fail`),
              content: translate(`${error}`),
              color: 'danger',
              icon: 'danger'
            })
          } else {
            BasicAlertPopup({
              title: translate(`fail`),
              color: 'danger',
              icon: 'danger',
              content: translate(`Please_check_the_information_again`)
            })
          }
          clearTimeout(idCountdown.current)
          setOtpCountdown(0)
        }
      })
  }
  useEffect(() => {
    if (otpCountdown !== 0) {
      idCountdown.current = setTimeout(() => {
        setOtpCountdown(otpCountdown - 1)
      }, 1000)
    }
  }, [otpCountdown])

  return (
    <span
      className={`send_code background_primary ${disabled || otpCountdown > 0 ? 'isDisable' : ''}`}
      onClick={() => {
        handleSendOTP()
      }}>
      {!otpCountdown && translate('send_code')}
      {otpCountdown === 0 ? '' : `${moment.utc(otpCountdown * 1000).format('mm:ss')}`}
    </span>
  )
}
