/* eslint-disable react-hooks/exhaustive-deps */
import ContentDetailPost from 'components/Advanced/ContentDetailPost'
import { BasicPopupConfirmWrap } from 'components/Basic/BasicPopup/BasicPopupConfirmWrap/BasicPopupConfirmWrap'
import useCommonHook from 'hooks/useCommonHook'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CustomerService from 'services/customerMessage'

const NotificationContext = createContext(null)

export function NotificationProvider({ children }) {
  const user = useSelector((state) => state.member)
  const isLoadingResource = useSelector((state) => (state.loadingResource.isLoadingResource))
  const { translate } = useCommonHook()
  const [numberNotificationUnread, setNumberNotificationUnread] = useState(0)
  const [numberNotificationUnreadImportant, setNumberNotificationUnreadImportant] = useState(0)
  const [dataImportantNotification, setDataImportantNotification] = useState(undefined)

  useEffect(() => {
    if (!user?.token) return
    if (isLoadingResource) return

    getUnreadNotificationCount()
    getUnreadImportantNotificationCount()
    const interval = setInterval(() => {
      getUnreadNotificationCount()
      getUnreadImportantNotificationCount()
    }, 60000);

    return () => { clearInterval(interval) }
  }, [isLoadingResource, user?.token])

  function getUnreadNotificationCount() {
    CustomerService.getUnreadNotificationCount({ filter: {} }).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        let total = data.total
        if (total > 99) {
          total = "99+"
        }
        setNumberNotificationUnread(total)
      }
    })
  }
  function getUnreadImportantNotificationCount() {
    CustomerService.getUnreadNotificationCount({ filter: { isImportant: 1 } }).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        setNumberNotificationUnreadImportant(data.total)
      }
    })
  }

  function getImportantNotification() {
    CustomerService.getListNotification({
      filter: { isImportant: 1, isRead: 0 }
    }).then((result) => {
      const { isSuccess, data } = result
      if (isSuccess) {
        setDataImportantNotification(data?.data?.[0])
      }
    })
  }

  function readNotification(id) {
    CustomerService.readNotification({
      id
    }).then(() => { })
  }

  useEffect(() => {
    const pathname = window.location.pathname
    const pathsToIgnore = ['/gameLobby', '/launchGame', '/gameUrl/', '/iframe']
    if (pathsToIgnore.some(path => pathname.startsWith(path))) {
      return
    }

    if (numberNotificationUnreadImportant > 0) {
      getImportantNotification()
    }
  }, [numberNotificationUnreadImportant])

  return (
    <NotificationContext.Provider
      value={{
        numberNotificationUnread,
      }}>
      {children}
      <BasicPopupConfirmWrap
        isOpen={dataImportantNotification ? true : false}
        title={translate('notification')}
        onCloseCircle={() => {
          readNotification(dataImportantNotification?.customerMessageId)
          setDataImportantNotification(undefined)
        }}>
        <ContentDetailPost className="notifi-detail"
          title={dataImportantNotification?.customerMessageTitle}
          bannerUrl={dataImportantNotification?.customerMessageImage || dataImportantNotification?.groupCustomerMessageImage}
          content={dataImportantNotification?.customerMessageContent}
          paddingBottom={false}
        />
      </BasicPopupConfirmWrap>
    </NotificationContext.Provider>
  )
}

export function useNotification() {
  return useContext(NotificationContext)
}
