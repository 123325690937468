import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import './index.scss'
import useCommonHook from 'hooks/useCommonHook'
import { Header } from 'components/Layout/Header/Header'
import BasicSegmented from 'components/Basic/BasicSegmented/BasicSegmented'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import { IconIconCrypton } from 'assets/icons/iconAdvanced'
import { useHistory, useLocation } from 'react-router-dom'
import { usePaymentMethods } from 'hooks/usePaymentMethod'
import { PAYMENT_METHOD_USAGE } from 'hooks/usePaymentMethod'
import { MAX_PAYMENT_METHOD_OF_USER } from 'hooks/usePaymentMethod'
import { IconPlusCircleOutline } from 'assets/icons/iconBasic'
import { APP_IMAGES } from 'assets/images/AppImages'
import { useSystem } from 'context/SystemContext'
import { useUser } from 'context/UserContext'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import { routes } from 'App'

const Setting = () => {
  const { system } = useSystem()
  const { user } = useUser()
  const { translate } = useCommonHook()

  const segmentedList = [
    {
      value: PAYMENT_METHOD_USAGE.ATM_BANK,
      label: translate('label_bank')
    },
    {
      value: PAYMENT_METHOD_USAGE.USDT,
      label: PAYMENT_METHOD_USAGE.USDT
    }
  ]

  const itemsTabShow = {
    USDT: {
      name: translate('usdt_address_2'),
      title: translate('Your_wallet'),
      buttonText: translate('Add_wallet_USDT'),
      buttonTextNoAcc: translate('Add_wallet_address'),
      contentNoAccount: translate('note_notify_no_account_usdt'),
      card: {
        backgroundClass: 'Setting_card_bgUSDT',
        label_top: translate('Network'),
        label_bottom: translate('Wallet_address')
      },
      maxLimitedPayment: system?.maxLimitedPaymentUSDT || 1
    },
    ATM_BANK: {
      name: translate('bank_account'),
      title: translate('Your_bank_account'),
      buttonText: translate('Add_bank_account'),
      buttonTextNoAcc: translate('Add_account'),
      contentNoAccount: translate('note_notify_no_account_bank'),
      card: {
        backgroundClass: 'Setting_card_bgBANK',
        label_top: translate('Account_owner'),
        label_bottom: translate('account_number')
      },
      maxLimitedPayment: system?.maxLimitedPaymentBank || 1
    }
  }

  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [tab, setTab] = useState(searchParams?.get('tab') || PAYMENT_METHOD_USAGE.ATM_BANK)
  const { data, setData, loading, showPopupService } = usePaymentMethods(tab)

  function cutStringBank(text) {
    const length = text.trim().length;
    const prefix = length < 4 ? "*".repeat(4 - length) : "";
    return prefix + text.slice(-4);
  }
  function cutStringUsdt(text) {
    const first = text.trim().slice(0, 1)
    const lengthLast = text.trim().length - 1;
    const prefix = lengthLast < 5 ? "*".repeat(5 - lengthLast) : "";
    return first + "*******" + prefix + text.slice(-5);
  }

  const handleChangeTab = (tab) => {
    if (loading) return
    setTab(tab)
    setData([])
    const params = {}
    if (tab) {
      params.tab = tab
    }
    const urlSearchParams = new URLSearchParams(params)
    history.replace({
      pathname: location.pathname,
      search: urlSearchParams.toString()
    })
  }

  const handleClickButton = () => {
    if (
      (data?.length >= itemsTabShow?.[tab]?.maxLimitedPayment && tab === PAYMENT_METHOD_USAGE.USDT) ||
      (data?.length >= itemsTabShow?.[tab]?.maxLimitedPayment && tab === PAYMENT_METHOD_USAGE.ATM_BANK)
    ) {
      showPopupService()
    } else {
      if (tab === PAYMENT_METHOD_USAGE.ATM_BANK && !user?.firstName) {
        return BasicAlertPopup({
          content: translate("Please_complete_your_account_information_before_updating_your_bank_information"),
          color: "primary",
          icon: "warning",
          confirmButtonText: translate("Go"),
          showCloseButton: true,
          onConfirm: () => {
            history.push(routes?.accountInfo?.path);
          },
        });
      }
      history.push('/payment-method-insert/' + tab)
    }
  }

  return (
    <div className="Setting">
      <Header title={translate('Bank_management')} />
      <BasicSegmented backgroudColor="transparent" backgroudAciveColor="transparent" options={segmentedList} value={tab} onChange={handleChangeTab} block={itemsTabShow?.[tab]?.maxLimitedPayment} />
      <div className="Setting_content">
        {data.length > 0 &&
          data.map((item, index) => (
            <div key={index} className={`Setting_card ${itemsTabShow?.[tab]?.card?.backgroundClass}`}>
              <div className="Setting_card_top">
                {tab === PAYMENT_METHOD_USAGE.USDT && (
                  <>
                    <IconIconCrypton height={28} width={28} />
                    <p>{item?.paymentMethodReceiverName}</p>
                  </>
                )}
                {tab === PAYMENT_METHOD_USAGE.ATM_BANK && (
                  <>
                    <img src={item?.paymentMethodImageUrl} alt="" height={28} />
                    <p>
                      {translate('label_bank')} {item?.paymentMethodReferName}
                    </p>
                  </>
                )}
              </div>
              <div className="Setting_card_line" />
              <div className="Setting_card_content">
                {
                  tab === PAYMENT_METHOD_USAGE.ATM_BANK && (
                    <div className='Setting_card_content_identity'>
                      <p>****</p>
                      <p>****</p>
                      <p>{cutStringBank(item?.paymentMethodIdentityNumber)}</p>
                    </div>
                  )
                }
                {
                  tab === PAYMENT_METHOD_USAGE.USDT && (
                    <div className='Setting_card_content_identity'>
                      <p>{cutStringUsdt(item?.paymentMethodIdentityNumber)}</p>
                    </div>
                  )
                }
              </div>
            </div>
          ))}

        {data?.length === 0 && !loading && (
          <div className="Setting_box">
            <img src={APP_IMAGES.NoDataCardSetting} alt='' className="Setting_box_icon" />
            <p className="Setting_box_text">
              {itemsTabShow?.[tab]?.contentNoAccount}
            </p>
          </div>
        )}
        <BasicButton
          color="success"
          className="Setting_button"
          onClick={() => handleClickButton()}
          disabled={loading}>
          <IconPlusCircleOutline />
          <span>{data?.length === 0 ? itemsTabShow?.[tab]?.buttonTextNoAcc : itemsTabShow?.[tab]?.buttonText}</span>
        </BasicButton>
        {
          data.length > 0 &&
          <p className='Setting_text_note'>
            {translate('Max')} {itemsTabShow?.[tab]?.maxLimitedPayment} {itemsTabShow?.[tab]?.name}
          </p>
        }
      </div>
    </div>
  )
}

export default injectIntl(Setting)
