import { Spin } from 'antd'
import React from 'react'
import style from './index.module.scss'

const BasicButton = ({
  children,
  className = '',
  outlined = false,
  color = 'primary', // colors = ["primary", "secondary", "danger", "success", "info"];
  width = '100%',
  onClick,
  loading=false,
  ...rest
}) => {
  const basicClassName = `background_${color}${outlined ? '_outlined' : '_gradiant'}`
  return (
    <div style={{ width: width }}>
      <Spin spinning={loading}>
        <button className={`${style['BasicButton']} ${basicClassName} ${className}`} onClick={onClick} {...rest}>
          {children}
        </button>
      </Spin>
    </div>
  )
}

export default BasicButton
