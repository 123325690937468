import useCommonHook from "hooks/useCommonHook"
import React from "react"
import "./index.scss"

export default function Loading({ loadingText }) {
  const { translate } = useCommonHook()
  return (
    <div className="Loading">
      <div className="Loading_fullScreen max_width_screen_project">
        <div className="Loading_fullScreen_content">
          <img src="/logoWithText.png" alt="" height={40} />
          {
            loadingText ||
            <p>{translate('LOADING_TEXT')}</p>
          }
        </div>
      </div>
    </div>
  )
}