import { IconCycle } from 'assets/icons/iconBasic'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import useCommonHook from 'hooks/useCommonHook'
import React from 'react'
import './index.scss'
import useGetPointGameToPointWallet from 'actionsFeatures/actionsGetPointGameToPointWallet'

export default function GetPointGameToPointWallet({
  children,
  hideIcon = false,
  className = '',
}) {
  const { isLoadingWallet, handleGetAllWalletToPoint } = useGetPointGameToPointWallet()
  const { translate } = useCommonHook()

  return (
    <div
      onClick={() => {
        if (isLoadingWallet) return
        BasicAlertPopup({
          title: translate('notification'),
          content: translate('Do_you_want_to_transfer_all_game_balance_to_main_wallet'),
          color: 'primary',
          icon: 'warning',
          showCloseButton: true,
          confirmButtonText: translate('confirm'),
          onConfirm: handleGetAllWalletToPoint
        })
      }
      } >
      {
        !hideIcon
        && <IconCycle className={`GetPointGameToPointWallet ${isLoadingWallet ? 'GetPointGameToPointWallet_animation' : ''} ${className}`} />
      }
      {children}
    </div>
  )
}