import Request from './request'

export default class AppUsers {
  static async getDetailUserById(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/getDetailUserById',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, error, message })
        }
      })
    })
  }

  static async updateInfoUserWithOTP(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/updateInfoUserWithOTP',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, error, message })
        }
      })
    })
  }
  static async changePasswordUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/changePasswordUser',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data, message, error })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async getTermsOfService(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'SystemPosts/public/getTermsOfService',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
  static async getPrivacyPolicy(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'SystemPosts/public/getPrivacyPolicy',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve(result)
        } else {
          return resolve(result)
        }
      })
    })
  }
  static async changeSecondPasswordUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/changeSecondaryPassword',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async changePhoneNumberUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/changePhoneNumber',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async createSecondPasswordUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/userCreateSecondaryPassword',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async verifySecondaryPassword(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/verifySecondaryPassword',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async forgotSecondaryPasswordEmailOTP(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/forgotSecondaryPasswordEmailOTP',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async fogotSecondPasswordUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/userForgotSecondaryPassword',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async resetPasswordByToken(data = {}, headers) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/resetPasswordByToken',
        data,
        headers
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, statusCode, data, message, error })
        } else {
          return resolve({ isSuccess: false, statusCode, data, message, error })
        }
      })
    })
  }
  static async resetSecondaryPasswordByToken(data = {}, headers) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/resetSecondaryPasswordByToken',
        data,
        headers
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, statusCode, data, message, error })
        } else {
          return resolve({ isSuccess: false, statusCode, data, message, error })
        }
      })
    })
  }

  static async updateInfoUser(data = {}, headers) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/updateInfoUser',
        data,
        headers
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async requestVerifyKYC(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/submitIdentity',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async get2FACode(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'GET',
        path: `AppUsers/get2FACode?appUserId=${data.id}`,
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async verify2FA(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/verify2FA ',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async searchAccount(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/user/searchAccount',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async summaryBonusAmount(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/referUser/summaryBonusAmount',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async summaryDetailAllSystemUser(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUserDailyReport/user/summaryDetailAllSystemUser',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }

  static async summaryTotalReferBonus(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/referUser/summaryTotalReferBonus',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async summaryTotalUserReferF1(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/referUser/summaryTotalUserReferF1',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
  static async summaryTotalUserRefer(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'AppUsers/referUser/summaryTotalUserRefer',
        data
      }).then((result = {}) => {
        const { statusCode, data, message, error } = result

        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message, error })
        }
      })
    })
  }
}
