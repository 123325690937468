import { Header } from 'components/Layout/Header/Header'
import useCommonHook from 'hooks/useCommonHook'
import React, { useEffect, useMemo, useState } from 'react'
import { PAYMENT_METHOD_USAGE } from 'hooks/usePaymentMethod'
import BasicInput from 'components/Basic/BasicInput/BasicInput'
import { PAYMENT_METHOD_NETWORK } from 'hooks/usePaymentMethod'
import { useUser } from 'context/UserContext'
import SendCode from 'components/Advanced/SendCode/SendCode'
import classNames from 'classnames'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import PaymentMethod from 'services/paymentMethod'
import { PAYMENT_METHOD_TYPE } from 'hooks/usePaymentMethod'
import { useHistory, useParams } from 'react-router-dom'
import usePaymentMethodInsert from '../actions/useMethodInsertAction'
import { usePaymentMethods } from 'hooks/usePaymentMethod'
import { PAYMENT_UNIT } from 'hooks/usePaymentMethod'
import { MAX_PAYMENT_METHOD_OF_USER } from 'hooks/usePaymentMethod'
import BasicSelect from 'components/Basic/BasicSelect/BasicSelect'
import { OTP_TYPE } from 'constants/config'
import { Spin } from 'antd'
import { useSystem } from 'context/SystemContext'

export const errorOtpPaymentMethod = {
  OTP_FAILED: 'invalidOTP',
  OTP_EXPIRED: 'OTP_EXPIRED'
}
export const errorPaymentMethod = {
  INVALID_PAYMENT_METHOD_BANK_DATA: 'INVALID_PAYMENT_METHOD_BANK_DATA',
  MAX_LIMITED_PAYMENT_METHOD_BANK: 'MAX_LIMITED_PAYMENT_METHOD_BANK',
  MAX_LIMITED_PAYMENT_METHOD_USDT: 'MAX_LIMITED_PAYMENT_METHOD_USDT',
  MAX_LIMITED_PAYMENT_METHOD_PIT: 'MAX_LIMITED_PAYMENT_METHOD_PIT',
  INSERT_FAILED: 'INSERT_FAILED'
}
const PaymentMethodInsertUsdt = () => {
  const history = useHistory()
  const {system}=useSystem()
  const { type } = useParams()
  const { user } = useUser()
  const { translate } = useCommonHook()
  const { handleFormatHideEmail, handleGetOnlyString } = usePaymentMethodInsert()
  const [dataToAction, setDataToAction] = useState({
    paymentMethodName: PAYMENT_METHOD_USAGE.USDT,
    paymentMethodReceiverName: PAYMENT_METHOD_NETWORK[0].value
  })
  const [errorAccount, setErrorAccount] = useState()
  const [loading, setLoading] = useState(false)
  const [errorOTP, setErrorOTP] = useState(undefined)
  const { data, showPopupService } = usePaymentMethods(type)
  const [isLoadingCheckAccount, setIsLoadingCheckAccount] = useState(false)

  const isDisableSendCodeButton = useMemo(() => {
    if (
      !dataToAction?.paymentMethodIdentityNumber
      || !user.email
    ) {
      return true
    }
    return false
  }, [dataToAction, user])

  const isDisableSubmitButton = useMemo(() => {
    if (
      !dataToAction?.paymentMethodIdentityNumber
      || !user.email
      || errorOTP
      || errorAccount
      || !dataToAction?.otp
    ) {
      return true
    }
    return false
  }, [dataToAction, errorOTP, errorAccount, user])

  function _handleInserPaymentMethod() {
    if (data?.length >= (system?.maxLimitedPaymentUSDT || 1)) {
      return showPopupService()
    }

    const params = {
      data: {
        paymentMethodName: dataToAction?.paymentMethodName,
        paymentMethodIdentityNumber: dataToAction?.paymentMethodIdentityNumber,
        paymentMethodReceiverName: dataToAction?.paymentMethodReceiverName,
        paymentMethodType: PAYMENT_METHOD_TYPE[type],
        paymentMethodUnit: PAYMENT_UNIT.USDT
      },
      otp: dataToAction?.otp
    }

    setLoading(true)
    PaymentMethod.insertPaymentMethod(params).then((response) => {
      setLoading(false)
      const { isSuccess, error } = response
      if (isSuccess) {
        BasicAlertPopup({
          title: translate('Confirmed_successfully'),
          content: translate('Your_USDT_wallet_has_been_linked'),
          color: 'success',
          icon: 'success'
        })
        history.goBack()
      } else {
        if (error === "DUPLICATE_DATA") {
          setErrorAccount(
            translate("DUPLICATE_DATA_PAYMENT_METHOD_USDT")
          )
        }
        else if (error === errorOtpPaymentMethod?.[error]) {
          setErrorOTP(translate(errorOtpPaymentMethod?.[error]))
        }
        else if (error === "SEND_OTP_FAILED") {
          setErrorOTP(translate("invalidOTP"));
        }
        // errorPaymentMethod?.[error] sẽ kiểm tra error có trong errorPaymentMethod hay không để thông báo lỗi
        else if (errorPaymentMethod?.[error]) {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: 'danger',
            icon: 'danger',
            content: translate(errorPaymentMethod?.[error])
          })
        } else {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: 'danger',
            icon: 'danger',
            content: translate(`Please_check_the_information_again`)
          })
        }
      }
    })
  }

  function _handleCheckBankInfo(data) {
    if (!dataToAction?.paymentMethodIdentityNumber || !dataToAction?.paymentMethodName) {
      return setErrorAccount(undefined)
    }
    const params = {
      paymentMethodType: PAYMENT_METHOD_TYPE[type],
      paymentMethodName: data?.paymentMethodName,
      paymentMethodIdentityNumber: data?.paymentMethodIdentityNumber,
    }

    setIsLoadingCheckAccount(true)
    PaymentMethod.paymentMethodCheckBankInfo(params).then((response) => {
      setIsLoadingCheckAccount(false)
      const { isSuccess } = response
      if (isSuccess) {
        setErrorAccount(undefined)
      } else {
        setErrorAccount(translate("DUPLICATE_DATA_PAYMENT_METHOD_ATM"))
      }
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      _handleCheckBankInfo(dataToAction)
    }, 1500)
    return () => clearTimeout(timeout)
  }, [dataToAction?.paymentMethodIdentityNumber, dataToAction?.paymentMethodName])

  return (
    <div className="PaymentMethodInsert">
      <Header title={translate('Add_wallet_USDT')}></Header>

      <div className="PaymentMethodInsert_content">
        <div>
          <BasicSelect
            label={translate('network')}
            placeholder={translate('Select_network')}
            value={PAYMENT_METHOD_NETWORK.find((item) => item.value === dataToAction?.paymentMethodReceiverName)}
            options={PAYMENT_METHOD_NETWORK}
            onChange={(value) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodReceiverName: value
              })
            }}
          />
        </div>

        <div>
          <BasicInput
            label={translate('usdt_address')}
            placeholder={`${translate('Enter_wallet_address')} ${translate('USDT')}`}
            value={dataToAction?.paymentMethodIdentityNumber}
            onChange={(e) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodIdentityNumber: e.target.value
              })
            }}
            errorText={errorAccount}
            readOnly={isLoadingCheckAccount}
            suffix={
              isLoadingCheckAccount && <Spin />
            }
          />
        </div>
        <div>
          <BasicInput
            label={translate('Email_address')}
            value={handleFormatHideEmail(user.email)}
            disabled={true}
            suffix={<SendCode disabled={isDisableSendCodeButton} email={user.email} setErrorOTP={setErrorOTP} otpType={OTP_TYPE.ADD_PAYMENT_METHOD_CRYPTO} />}
          />
        </div>
        <div>
          <BasicInput
            label={translate('verifyOTP')}
            className={errorOTP ? 'PaymentMethodInsert_errorBorder' : ''}
            value={dataToAction?.otp}
            placeholder={translate('Enter_the_6_digit_number_you_just_received')}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setDataToAction({
                  ...dataToAction,
                  otp: handleGetOnlyString(e.target.value, dataToAction?.otp)
                })
                setErrorOTP(undefined)
              }
            }}
            onBlur={() => {
              if (!dataToAction?.otp) {
                setErrorOTP(translate('Please_enter_OTP_code'))
              }
            }}
            errorText={errorOTP}
          />
        </div>

        <BasicButton
          className={classNames({ isDisable: isDisableSubmitButton || loading || isLoadingCheckAccount })}
          onClick={() => {
            _handleInserPaymentMethod()
          }}
          color="success"
        >
          {translate('Confirm')}
        </BasicButton>
      </div>
    </div>
  )
}
export default PaymentMethodInsertUsdt
