export const LIST_DOMAIN_PRODUCT = [
  "cloudflare.pitgame1.pro",
  "gateway.pitgame.io",
  "gateway.pitgame.net",
  "gateway.pitgame2.games",
  "gateway.pitgame.vip",
  "admin-gateway.pitgame.vip",
  "merchant-gateway.pitgame.vip",
  "staff-gateway.pitgame.vip",
  "api-csn.okeda.net",
]

export const LIST_DOMAIN_DEV = [
  "deapi.ahb8888.jdb-yes.com",
  "dev-gateway.pitgame.vip",
  "cdn.okeda.net",
  "dev-gateway.pitgame.net",
]

export const LIST_DOMAIN_TO_CHECK = process.env.REACT_APP_RUNTIME_MODE === "production"
  ? LIST_DOMAIN_PRODUCT
  : LIST_DOMAIN_DEV