import React from 'react'
import { IconBell } from 'assets/icons/iconBasic'
import { useNotification } from 'context/notificationContext'

export default function Notification() {
  const { numberNotificationUnread } = useNotification()

  return (
    <div className="position-relative" role="button">
      <IconBell />
      {numberNotificationUnread && numberNotificationUnread !== 0 ? (
        <p
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{
            width: '16px',
            height: '16px',
            position: 'absolute',
            top: '-4px',
            right: '0px',
            padding: '2px',
            fontSize: '8px',
            fontWeight: 'var(--font_weight_primary)',
            background: 'var(--color_danger_gradiant)',
            color: 'var(--text_color_primary_in_background)',
          }}>
          {numberNotificationUnread}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}
