import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import './index.scss'
import Footer from 'components/Layout/Footer/Footer'
import classNames from 'classnames'
import { LoginByModalProvider } from 'Page/LoginByModal'

function LayoutPage(props) {
  const { showFooterMobile, Component, className = '', outerComponent } = props

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      const headerId = document.getElementById('header-sticky')
      if (headerId && headerId.classList) {
        if (window.pageYOffset > 0) {
          headerId.classList.toggle('sticky')
        } else {
          headerId.classList.remove('sticky')
        }
      }
    })
  }, [])

  useEffect(() => {
    if (props.isAuth) {
      // document.getElementById('root').style.height = 'calc(100vh - 120px)'
    } else {
      document.getElementById('root').style.height = '100vh'
    }
  }, [props, props.isAuth])

  useEffect(() => {
    const bodyId = document.getElementById('body-root')
    if (bodyId) {
      bodyId.classList.add(className || '')
    }
  }, [className])

  return (
    <>
      {
        outerComponent ? <>
          <LoginByModalProvider >
            <div className='padding_from_header'>
              <Component {...props} />
            </div>
          </LoginByModalProvider>
        </> : <div className="layout">
          <LoginByModalProvider >
            <div
              className={classNames
                ("padding_from_header",
                  {
                    paddingFooter: showFooterMobile
                  }
                )}>
              <div className='position-relative'>
                <Component {...props} />
              </div>
            </div>
            {showFooterMobile && <Footer />}
          </LoginByModalProvider>
        </div>
      }

    </>
  )
}

const mapStateToProps = (state) => ({
  member: state.member || {}
})

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage)
