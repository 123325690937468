
export const keyStorageProjectName = (text) => {
  const projectName = process.env.REACT_APP_PROJECT_NAME
  return projectName ? `${projectName}__${text}` : text
}
export const LocalStorageManager = {
  getItem(key) {
    return localStorage.getItem(keyStorageProjectName(key))
  },
  setItem(key, value) {
    return localStorage.setItem(keyStorageProjectName(key), value);
  },
  removeItem(key) {
    return localStorage.removeItem(keyStorageProjectName(key));
  },
  clear() {
    return localStorage.clear();
  }
}