//hỉnh ảnh màn hình cửa hàng
export const APP_IMAGES = {
  IconAlertPopupSuccess: '/assets/images/basicAlert/IconDone.png',
  IconAlertPopupSuccessTransfer: '/assets/images/basicAlert/IconDoneTransfer.png',
  IconAlertPopupDanger: '/assets/images/basicAlert/IconDanger.png',
  IconAlertPopupService: '/assets/images/basicAlert/IconService.png',
  IconMaintenancePopupService: '/assets/images/basicAlert/IconMaintenance.png',
  IconAlertPopupAuth: '/assets/images/basicAlert/IconAuth.png',
  IconAlertPopupWarning: '/assets/images/basicAlert/IconWarning.png',
  BackgroundCard: "/assets/images/background/BackgroundCard.png",
  BackgroundDownload: "/assets/images/background/BackgroundDownload.png",
  BannerCopyBet: "/assets/images/CopyBet/Banner.png",
  RegisterExpertCopyBet: "/assets/images/CopyBet/RegisterExpert.png",
  NoDataCardSetting: "/assets/images/noData/CreditCardSettings.png",
  ProfileCommonProblem: "/assets/images/profile/VanDeThuongGap.png",
  ProfileEndow: "/assets/images/profile/UuDai.png",
  ProfileMemberInformation: "/assets/images/profile/ThongTinHoiVien.png",
  ProfileDownloadAPP: "/assets/images/profile/TaiAPP.png",
  ProfileBankManagement: "/assets/images/profile/QuanLyNganHang.png",
  ProfileScoreHistory: "/assets/images/profile/LichSuDiemSo.png",
  ProfilePersonalRefund: "/assets/images/profile/HoanTraCaNhan.png",
  ProfileIntroducingPitgame: "/assets/images/profile/GioiThieuVePitgame.png",
  ProfilePitgameAgent: "/assets/images/profile/DaiLyPitgame.png",
  ProfileInsurance: "/assets/images/Insurance/insuranceLogo.png",
  ProfileBetHistory: "/assets/images/profile/LichSuDatCuoc.png",
  ProfileCopyBet: "/assets/images/profile/SaoChepLenh.png",
  ProfileChangePass: "/assets/images/profile/ThayDoiMatKhau.png",
  PolicyInsurance: "/assets/images/Insurance/policy.png",
  InsuranceLayer: "/assets/images/Insurance/layer-insurance.png",
  InsuranceLogo: "/assets/images/Insurance/insuranceLogo.png",
}

