import React from 'react'
import './BasicInput.scss'
import Input from 'antd/lib/input/Input'
import { IconEyeClosed } from 'assets/icons/iconBasic'
import { IconEye } from 'assets/icons/iconBasic'

export default function BasicInput({ className = '', suffix, type, label, errorText, styleCustomized = "1", ...rest }) {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <div>
      <p className='BasicInput_label'>{label}</p>
      <Input
        className={`BasicInput BasicInput_${styleCustomized} ${className}`}
        size="large"
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        suffix={suffix ||
          (type === 'password'
            ? <div onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <IconEyeClosed height={24} width={24} />
                : <IconEye height={24} width={24} />}
            </div>
            : null)}
        {...rest} />
      <p className='BasicInput_error'>{errorText}</p>
    </div>
  )
}
