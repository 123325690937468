import React from 'react'
import './BasicPopupConfirmWrap.scss'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import { IconCloseCircle } from 'assets/icons/iconBasic'
import { APP_IMAGES } from 'assets/images/AppImages'

export function BasicPopupConfirmWrap({ isOpen, onClose, className = '', title, children, onConfirm, cancelText, confirmText, onCloseCircle }) {
  return (
    isOpen && (
      <div className="BasicPopupConfirmWrap max_width_screen_project">
        <div className="max_width_screen_project BasicPopupConfirmWrap_wrap">
          <div className='BasicPopupConfirmWrap_wrap_head'>
            <div className='BasicPopupConfirmWrap_wrap_head_background' style={{ backgroundImage: `url(${APP_IMAGES?.BackgroundCard})` }} />
            {
              onCloseCircle &&
              <IconCloseCircle className="BasicPopupConfirmWrap_wrap_head_close" onClick={onCloseCircle} />
            }
            <p className="BasicPopupConfirmWrap_wrap_title">{title}</p>
          </div>
          <div className={`BasicPopupConfirmWrap_wrap_body ${className}`}>{children}</div>
          <div className="BasicPopupConfirmWrap_wrap_buttons">
            {
              onClose &&
              <BasicButton
                className="BasicPopupConfirmWrap_wrap_buttons_cancel"
                onClick={() => {
                  onClose()
                }}>
                {cancelText}
              </BasicButton>
            }
            {
              onConfirm &&
              <BasicButton
                color="danger"
                onClick={() => {
                  onConfirm()
                }}>
                {confirmText}
              </BasicButton>
            }
          </div>
        </div>
      </div>
    )
  )
}
