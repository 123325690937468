import React from "react"
import "./index.scss"
export default function ContentDetailPost({ className, title, content, bannerUrl, paddingBottom = true }) {
  return <div className={`ContentDetailPost ${className}`}>
    {
      title &&
      <div dangerouslySetInnerHTML={{ __html: title }} className="ContentDetailPost__title" />
    }
    {
      bannerUrl &&
      <img src={bannerUrl || undefined} alt="" className="ContentDetailPost__banner" />
    }
    {
      content &&
      <div dangerouslySetInnerHTML={{ __html: content }} className="ContentDetailPost__content" />
    }
    {
      paddingBottom && <div style={{ height: 200 }}></div>
    }
  </div>
}