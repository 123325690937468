import Request from './request'

export default class CustomerService {
  static async getListNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/getListNotification',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getListGroupNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GroupCustomerMessage/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getListNotificationEvent(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'SystemPosts/user/getList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async readNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/readNotification',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async readGroupCustomerMessage(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GroupCustomerMessage/user/readGroupCustomerMessage',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async unReadNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/unReadNotification',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async deleteNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/deleteNotification',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailMessage(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/getDetailMessage',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailGroupMessage(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'GroupCustomerMessage/user/getDetail',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailEvent(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'SystemPosts/user/getDetail',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async readAllNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/readAllNotification',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async deleteAllNotification(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/deleteAllNotification',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getUnreadNotificationCount(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'CustomerMessage/user/getUnreadNotificationCount',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailPaymentDeposit(messageRefId) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentDepositTransaction/user/userGetDetailById',
        data: {
          id: messageRefId
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailExchangeTransaction(messageRefId) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentExchangeTransaction/user/userGetDetailById',
        data: {
          id: messageRefId
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailPaymentWithdraw(messageRefId) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentWithdrawTransaction/user/userGetDetailById',
        data: {
          id: messageRefId
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async getDetailServicePackage(messageRefId) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'ServicePackageTransfer/user/getDetailTransferRequest',
        data: {
          id: messageRefId
        }
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async userGetListDeposit(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentDepositTransaction/user/userGetList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async userGetBonus(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentExchangeTransaction/user/userGetExchangeHistory',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async userGetListWithdraw(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentWithdrawTransaction/user/userGetList',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
  static async userGetListTransaction(data = {}) {
    return new Promise((resolve) => {
      Request.send({
        method: 'POST',
        path: 'PaymentExchangeTransaction/user/userGetExchangeHistory',
        data
      }).then((result = {}) => {
        const { statusCode, data, message } = result
        if (statusCode === 200) {
          return resolve({ isSuccess: true, data })
        } else {
          return resolve({ isSuccess: false, message })
        }
      })
    })
  }
}
