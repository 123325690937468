import { Header } from 'components/Layout/Header/Header'
import useCommonHook from 'hooks/useCommonHook'
import React, { useEffect, useMemo, useState } from 'react'
import BasicInput from 'components/Basic/BasicInput/BasicInput'
import { useUser } from 'context/UserContext'
import SendCode from 'components/Advanced/SendCode/SendCode'
import classNames from 'classnames'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import PaymentMethod from 'services/paymentMethod'
import { PAYMENT_METHOD_TYPE } from 'hooks/usePaymentMethod'
import { errorOtpPaymentMethod, errorPaymentMethod } from './PaymentMethodInsertUsdt'
import usePaymentMethodInsert from '../actions/useMethodInsertAction'
import { useHistory, useParams } from 'react-router-dom'
import { usePaymentMethods } from 'hooks/usePaymentMethod'
import { PAYMENT_UNIT } from 'hooks/usePaymentMethod'
import { MAX_PAYMENT_METHOD_OF_USER } from 'hooks/usePaymentMethod'
import BasicSelect from 'components/Basic/BasicSelect/BasicSelect'
import { useSystem } from 'context/SystemContext'
import { OTP_TYPE } from 'constants/config'
import { Spin } from 'antd'
import { xoa_dau } from 'helper/common'

const PaymentMethodInsertBank = () => {
  const history = useHistory()
  const { translate, directCustomerService } = useCommonHook()
  const { type } = useParams()
  const { user } = useUser()
  const { system } = useSystem()
  const { metaData } = useSystem()
  const paymentUnit =
    metaData?.SYSTEM_NATIONAL && PAYMENT_UNIT?.[Object.values(metaData?.SYSTEM_NATIONAL)?.find((e) => e.id === user?.appUserNation)?.label]
  const nationText =
    metaData?.SYSTEM_NATIONAL && translate(Object.values(metaData?.SYSTEM_NATIONAL)?.find((e) => e.id === user?.appUserNation || 1)?.label)

  const [loading, setLoading] = useState(false)
  const { handleFormatHideEmail, handleGetOnlyString } = usePaymentMethodInsert()

  const [dataToAction, setDataToAction] = useState({
    paymentMethodReceiverName: xoa_dau(user?.firstName?.toUpperCase() || ''),
  })
  const [dataListBank, setDataListBank] = useState([])
  const [errorOTP, setErrorOTP] = useState(undefined)
  const [errorAccount, setErrorAccount] = useState(undefined)
  const { data, showPopupService } = usePaymentMethods(type)
  const [isLoadingCheckAccount, setIsLoadingCheckAccount] = useState(false)

  const isDisableSendCodeButton = useMemo(() => {
    if (
      !dataToAction?.paymentMethodIdentityNumber ||
      !user.email ||
      !dataToAction?.paymentMethodReferName ||
      !dataToAction?.paymentMethodReceiverName
    ) {
      return true
    }
    return false
  }, [dataToAction, user])

  const isDisableSubmitButton = useMemo(() => {
    if (
      !dataToAction?.paymentMethodIdentityNumber ||
      !user.email ||
      errorOTP ||
      !dataToAction?.paymentMethodReferName ||
      !dataToAction?.paymentMethodReceiverName ||
      !dataToAction?.otp ||
      errorAccount
    ) {
      return true
    }
    return false
  }, [dataToAction, errorOTP, errorAccount, user])

  function _handleInserPaymentMethod() {
    if (data?.length >= (system?.maxLimitedPaymentBank || 1)) {
      return showPopupService()
    }

    const params = {
      data: {
        paymentMethodName: dataToAction?.paymentMethodName,
        paymentMethodType: PAYMENT_METHOD_TYPE[type],
        paymentMethodUnit: paymentUnit,
        paymentMethodIdentityNumber: dataToAction?.paymentMethodIdentityNumber,
        paymentMethodReferName: dataToAction?.paymentMethodReferName,
        paymentMethodReceiverName: dataToAction?.paymentMethodReceiverName,
        paymentMethodImageUrl: dataToAction?.paymentMethodImageUrl,
        paymentMethodNationId: user?.appUserNation
      },
      otp: dataToAction?.otp
    }

    setLoading(true)
    PaymentMethod.insertPaymentMethod(params).then((response) => {
      setLoading(false)
      const { isSuccess, error } = response
      if (isSuccess) {
        BasicAlertPopup({
          title: translate('Confirmed_successfully'),
          content: translate('Your_bank_account_has_been_linked'),
          color: 'success',
          icon: 'success'
        })
        history.goBack()
      } else {
        if (error === "DUPLICATE_DATA") {
          setErrorAccount(
            translate("DUPLICATE_DATA_PAYMENT_METHOD_USDT")
          )
        }
        else if (error === "SEND_OTP_FAILED") {
          setErrorOTP(translate("invalidOTP"));
        }
        else if (error === errorOtpPaymentMethod?.[error]) {
          setErrorOTP(translate(errorOtpPaymentMethod?.[error]))
        }
        // errorPaymentMethod?.[error] sẽ kiểm tra error có trong errorPaymentMethod hay không để thông báo lỗi
        else if (errorPaymentMethod?.[error]) {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: 'danger',
            icon: 'danger',
            content: translate(errorPaymentMethod?.[error])
          })
        } else {
          BasicAlertPopup({
            title: translate(`Link_failed`),
            color: 'danger',
            icon: 'danger',
            content: translate(`Please_check_the_information_again`)
          })
        }
      }
    })
  }

  useEffect(() => {
    PaymentMethod.getPublicBankList({
      filter: {
        paymentMethodNationId: user.appUserNation || 1
      }
    }).then((response) => {
      const data = response?.data?.map((item) => ({
        value: item?.code,
        label: (
          <div>
            <img
              src={item?.logo}
              alt=""
              height={20}
              style={{
                border: '1px solid var(--border_card_color)',
                marginRight: '6px',
                objectFit: 'cover',
                minWidth: '50px',
              }}
            />
            <span>{item?.shortName}</span>
          </div>
        ),
        logo: item?.logo,
        shortName: item?.shortName,
        name: item?.name
      }))
      setDataListBank(data || [])
    })
  }, [user.appUserNation])


  function _handleCheckBankInfo(data) {
    if (!dataToAction?.paymentMethodIdentityNumber || !dataToAction?.paymentMethodName) {
      return setErrorAccount(undefined)
    }
    const params = {
      paymentMethodType: PAYMENT_METHOD_TYPE[type],
      paymentMethodName: data?.paymentMethodName,
      paymentMethodIdentityNumber: data?.paymentMethodIdentityNumber,
      paymentMethodReceiverName: dataToAction?.paymentMethodReceiverName
    }
    setIsLoadingCheckAccount(true)
    PaymentMethod.paymentMethodCheckBankInfo(params).then((response) => {
      setIsLoadingCheckAccount(false)
      const { isSuccess, error } = response
      if (isSuccess) {
        setErrorAccount(undefined)
      } else {
        if (error === "DUPLICATE_DATA_PAYMENT_METHOD_ATM") {
          setErrorAccount(translate("DUPLICATE_DATA_PAYMENT_METHOD_ATM"))
        } else if (error === "INVALID_PAYMENT_METHOD_BANK_DATA") {
          setErrorAccount(translate("INVALID_PAYMENT_METHOD_BANK_DATA"))
        } else if (error === "NO_MATCH_USERNAME_ACCOUNT_EXISTED") {
          setErrorAccount(translate("NO_MATCH_USERNAME_ACCOUNT_EXISTED"))
        } else if (error === "DUPLICATE_DATA") {
          setErrorAccount(translate(`DUPLICATE_DATA_PAYMENT_METHOD_ATM`))
        }
      }
    })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (dataToAction?.paymentMethodIdentityNumber && dataToAction?.paymentMethodName && dataToAction?.paymentMethodReceiverName) {
        _handleCheckBankInfo(dataToAction)
      }
    }, 2000)
    return () => clearTimeout(timeout)
  }, [dataToAction?.paymentMethodIdentityNumber, dataToAction?.paymentMethodName, dataToAction?.paymentMethodReceiverName])

  return (
    <div className="PaymentMethodInsert">
      <Header title={translate('Add_bank_account')}></Header>
      <div className="PaymentMethodInsert_content">
        <div>
          <BasicInput
            label={translate('Full_name')}
            placeholder={translate('Enter_your_real_name')}
            value={dataToAction?.paymentMethodReceiverName}
            onChange={(e) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodReceiverName: xoa_dau(e.target.value?.toUpperCase() || ''),
              })
            }}
            disabled={user.firstName}
          />
          {
            !user.firstName &&
            <p className="PaymentMethodInsert_bank_note">{translate('note_insert_payment_method_bank')}</p>
          }

        </div>

        <div>
          <BasicSelect
            label={translate('label_bank')}
            showSearch
            placeHolderSearch={translate('Enter_the_bank_name')}
            placeholder={translate('Select_bank')}
            value={dataListBank.find((item) => item.value === dataToAction?.paymentMethodReferName)}
            options={dataListBank}
            onChange={(value) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodName: dataListBank.find((item) => item.value === value)?.shortName,
                paymentMethodReferName: value,
                paymentMethodImageUrl: dataListBank.find((item) => item.value === value)?.logo
              })
            }}
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase()) ||
              option.shortName.toLowerCase().includes(input.toLowerCase()) ||
              option.name.toLowerCase().includes(input.toLowerCase())
            }
            onClick={() =>
              dataListBank.length === 0 &&
              BasicAlertPopup({
                content: translate('Currently_the_system_linking_banks_in_country_is_under_maintenance_Please_contact_Customer_Service_for_support', {
                  country: nationText
                }),
                color: 'info',
                icon: 'service',
                confirmButtonText: translate('contact'),
                showCloseButton: true,
                onConfirm: () => {
                  directCustomerService()
                }
              })
            }
          />
        </div>
        <div>

          <BasicInput
            label={translate('account_number')}
            placeholder={translate('Enter_your_account_number')}
            value={dataToAction?.paymentMethodIdentityNumber}
            onChange={(e) => {
              setDataToAction({
                ...dataToAction,
                paymentMethodIdentityNumber: handleGetOnlyString(e.target.value, dataToAction?.paymentMethodIdentityNumber)
              })
            }}
            errorText={errorAccount}
            readOnly={isLoadingCheckAccount}
            suffix={
              isLoadingCheckAccount && <Spin />
            }
          />
        </div>
        <div>
          <BasicInput
            label={translate('Email_address')}
            value={handleFormatHideEmail(user.email)}
            disabled={true}
            suffix={<SendCode disabled={isDisableSendCodeButton} email={user.email} otpType={OTP_TYPE.ADD_PAYMENT_METHOD_BANK} />}
          />
        </div>
        <div>

          <BasicInput
            label={translate('verifyOTP')}
            className={errorOTP ? 'PaymentMethodInsert_errorBorder' : ''}
            value={dataToAction?.otp}
            placeholder={translate('inputOTP')}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setDataToAction({
                  ...dataToAction,
                  otp: handleGetOnlyString(e.target.value, dataToAction?.otp)
                })
                setErrorOTP(undefined)
              }
            }}
            onBlur={() => {
              if (!dataToAction?.otp) {
                setErrorOTP(translate('Please_enter_OTP_code'))
              }
            }}
            errorText={errorOTP}
          />
        </div>

        <BasicButton
          color="success"
          className={classNames({ isDisable: isDisableSubmitButton || loading || isLoadingCheckAccount })}
          onClick={() => {
            _handleInserPaymentMethod()
          }}>
          {translate('Confirm')}
        </BasicButton>
      </div>
    </div>
  )
}
export default PaymentMethodInsertBank
