import { routes } from 'App'
import { useModalLoginHooks } from 'Page/LoginByModal'
import { message } from 'antd'
import { useSystem } from 'context/SystemContext'
import { useUser } from 'context/UserContext'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default function useCommonHook() {
  const intl = useIntl()
  const { user } = useUser()
  const history = useHistory()
  const { system } = useSystem()
  const { setIsOpenModalLogin } = useModalLoginHooks()
  const translate = useCallback((id, options = {}) => id && intl.formatMessage({ id }, options), [intl])

  function copyToClipboard(text, messageNotify = null) {
    var selected = false
    var el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    if (document.getSelection().rangeCount > 0) {
      selected = document.getSelection().getRangeAt(0)
    }
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    if (selected) {
      document.getSelection().removeAllRanges()
      document.getSelection().addRange(selected)
    }
    message.success(messageNotify || translate('Copy_successfully'))
  }

  function directRoute(path) {
    if (user?.token) {
      history.push(path)
    } else {
      setIsOpenModalLogin(true)
    }
  }

  function directCustomerService() {
    if (user?.token) {
      window.location.href = system?.supportChatUrlVI
    } else {
      window.location.href = system?.supportChatUrlEN
    }
  }

  return {
    copyToClipboard,
    translate,
    directRoute,
    directCustomerService
  }
}
