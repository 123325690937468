import Axios from "axios";

export async function sendTelegramBot(data) {
  const token = process.env.REACT_APP_TELEGRAM_BOT_TOKEN;
  const chatId = process.env.REACT_APP_TELEGRAM_BOT_CHAT_ID;
  const url = `https://api.telegram.org/bot${token}/sendMessage`;
  if (!token || !chatId) {
    return
  }
  try {
    await Axios.get(url, {
      params: {
        chat_id: chatId,
        text: data
      }
    });

  } catch (error) {
    console.error('Error sending message:', error);
  }
}