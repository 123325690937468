import React from 'react'
import LanguageCambodian from 'assets/icons/iconLanguage/LanguageCambodian.png'
import LanguageChinese from 'assets/icons/iconLanguage/LanguageChinese.png'
import LanguageEnglish from 'assets/icons/iconLanguage/LanguageEnglish.png'
import LanguageKorean from 'assets/icons/iconLanguage/LanguageKorean.png'
import LanguageMalaysian from 'assets/icons/iconLanguage/LanguageMalaysian.png'
import LanguageThailand from 'assets/icons/iconLanguage/LanguageThailand.png'
import LanguageVietnamese from 'assets/icons/iconLanguage/LanguageVietnamese.png'
import LanguageIndonesia from 'assets/icons/iconLanguage/LanguageIndonesia.png'

export const APP_LANGUAGE = {
  vi: {
    id: 1,
    icon: <img src={LanguageVietnamese} alt='' width={20} height={20} />,
    title: 'Tiếng Việt',
    value: 'vi',
    label: 'VN',
    name: 'Vietnam'
  },
  en: {
    id: 2,
    icon: <img src={LanguageEnglish} alt='' width={20} height={20} />,
    title: 'English',
    value: 'en',
    label: 'EN',
    name: 'English'
  },
  cn: {
    id: 3,
    icon: <img src={LanguageChinese} alt='' width={20} height={20} />,
    title: '中国',
    value: 'cn',
    label: 'CN',
    name: 'China'
  },
  ko: {
    id: 4,
    icon: <img src={LanguageKorean} alt='' width={20} height={20} />,
    title: '한국어',
    value: 'ko',
    label: 'KO',
    name: 'Karean'
  },
  th: {
    id: 5,
    icon: <img src={LanguageThailand} alt='' width={20} height={20} />,
    title: 'ภาษาไทย',
    value: 'th',
    label: 'TH',
    name: 'Thailand'
  },
  ms: {
    id: 6,
    icon: <img src={LanguageMalaysian} alt='' width={20} height={20} />,
    title: 'Bahasa Malaysia',
    value: 'ms',
    label: 'MY',
    name: 'Malaysian'
  },
  in: {
    id: 9,
    icon: <img src={LanguageIndonesia} alt='' width={20} height={20} />,
    title: 'Bahasa Indonesia',
    value: 'in',
    label: 'IN',
    name: 'Indonesia'
  },
}

export const APP_NATION = {
  // km: {
  //   id: 7,
  //   icon: <img src={LanguageCambodian} alt='' width={20} height={20} />,
  //   title: "Cambodian",
  //   value: "km",
  //   name:"Cambodia",
  //   label:"Cambodia",
  // },
  cn: {
    id: 3,
    icon: <img src={LanguageChinese} alt='' width={20} height={20} />,
    title: '中国人',
    value: 'cn',
    name: 'China',
    label: 'Trung Quốc',
    unit: 'RMB'
  },
  en: {
    id: 2,
    icon: <img src={LanguageEnglish} alt='' width={20} height={20} />,
    title: 'English',
    value: 'en',
    name: 'England',
    label: 'Anh',
    unit: 'GBP'
  },
  in: {
    id: 9,
    icon: <img src={LanguageIndonesia} alt='' width={20} height={20} />,
    title: 'Indonesia',
    value: 'in',
    name: 'Indonesia',
    label: 'Indonesia',
    unit: 'IDR'
  },
  ko: {
    id: 4,
    icon: <img src={LanguageKorean} alt='' width={20} height={20} />,
    title: 'Korean',
    value: 'ko',
    name: 'South Korea',
    label: 'Hàn Quốc',
    unit: 'KRW'
  },
  ml: {
    id: 6,
    icon: <img src={LanguageMalaysian} alt='' width={20} height={20} />,
    title: 'Malaysian',
    value: 'ml',
    name: 'Malaysia',
    label: 'Malaysia',
    unit: 'MYR'
  },
  // pl: {
  //   icon: <img src={LanguageMalaysian} alt='' width={20} height={20} />,
  //   title: "Philippines",
  //   id: 8,
  //   value: "pl",
  //   name:"Philippines",
  //   label:"Philippines",
  // },
  th: {
    id: 5,
    icon: <img src={LanguageThailand} alt='' width={20} height={20} />,
    title: 'Thai',
    value: 'th',
    name: 'Thailand',
    label: 'Thái Lan',
    unit: 'THB'
  },
  vi: {
    id: 1,
    icon: <img src={LanguageVietnamese} alt='' width={20} height={20} />,
    title: 'Tiếng Việt',
    value: 'vi',
    name: 'Vietnam',
    label: 'Việt Nam',
    unit: 'VND'
  }
}
export const APP_PHONE = {
  // km: {
  //   id: 7,
  //   icon: <img src={LanguageCambodian} alt='' width={20} height={20}/>,
  //   title: "Cambodian",
  //   value: "km",
  // },
  vi: {
    id: 1,
    icon: <img src={LanguageVietnamese} alt='' width={20} height={20} />,
    title: 'Tiếng Việt',
    value: '+84'
  },
  cn: {
    id: 3,
    icon: <img src={LanguageVietnamese} alt='' width={20} height={20} />,
    title: '中国人',
    value: '+86'
  },
  en: {
    id: 2,
    icon: <img src={LanguageEnglish} alt='' width={20} height={20} />,
    title: 'English',
    value: '+44'
  },
  in: {
    id: 9,
    icon: <img src={LanguageIndonesia} alt='' width={20} height={20} />,
    title: 'Indonesia',
    value: '+62'
  },
  ko: {
    id: 4,
    icon: <img src={LanguageKorean} alt='' width={20} height={20} />,
    title: 'Korean',
    value: '+82'
  },
  ml: {
    id: 6,
    icon: <img src={LanguageMalaysian} alt='' width={20} height={20} />,
    title: 'Malaysian',
    value: '+60'
  },
  // pl: {
  //   icon: <img src={LanguageMalaysian} alt='' width={20} height={20}/>,
  //   title: "Philipines",
  //   id: 8,
  //   value: "+63",
  // },
  th: {
    id: 5,
    icon: <img src={LanguageThailand} alt='' width={20} height={20} />,
    title: 'Thai',
    value: '+66'
  }
}
export const getLanguageIdByValue = (value) => {
  let _appUserLanguageId = Object.values(APP_LANGUAGE).find((e) => e.value == value)?.id
  return _appUserLanguageId
}
