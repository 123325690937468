/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react'
import './Footer.scss'
import { routes } from 'App'
import { useIntl } from 'react-intl'
import { NavHome } from 'assets/icons/iconNavbar'
import { NavHomeBold } from 'assets/icons/iconNavbar'
import { NavBranch } from 'assets/icons/iconNavbar'
import { NavBranchBold } from 'assets/icons/iconNavbar'
import { NavFactory } from 'assets/icons/iconNavbar'
import { NavFactoryBold } from 'assets/icons/iconNavbar'
import { NavUser } from 'assets/icons/iconNavbar'
import { NavUserBold } from 'assets/icons/iconNavbar'
import classNames from 'classnames'
import { CardTransfer } from 'assets/icons/iconNavbar'
import { useSystem } from 'context/SystemContext'
import { APP_IMAGES } from 'assets/images/AppImages'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import { IconHistory } from 'assets/icons/iconBasic'
import { IconCardRecive } from 'assets/icons/iconBasic'
import { IconCardSend } from 'assets/icons/iconBasic'
import useCommonHook from 'hooks/useCommonHook'
import imgGift3D from '../../../assets/icons/iconNavbar/Gift3D.png'
import { IconNotificationBell } from 'assets/icons/iconBasic'

const isInsurance = process.env.REACT_APP_SHOW_BUTTON_INSURANCE
const iscopyBet = process.env.REACT_APP_SHOW_BUTTON_COPY_BET


export default function Footer() {
  const intl = useIntl()
  const { directRoute, directCustomerService } = useCommonHook()
  const t = useCallback((id) => intl.formatMessage({ id }), [intl])
  const { system } = useSystem()
  const [isSpecial, setIsSpecial] = React.useState(false)

  const listNav = [
    window.location.pathname === routes.home.path
      ? {
        title: t('Endow'),
        path: routes.endow.path,
        icon: <img src={imgGift3D} alt="" height={32} />,
        iconActive: <img src={imgGift3D} alt="" height={32} />
      }
      : {
        title: t('homepage'),
        path: routes.home.path,
        icon: <NavHome />,
        iconActive: <NavHomeBold />
      },
    {
      title: t('support'),
      action: () => directCustomerService(),
      icon: <NavBranch />,
      iconActive: <NavBranchBold />
    },
    {
      title: t('Deposit_withdraw'),
      action: () => setIsSpecial(!isSpecial),
      icon: <CardTransfer />,
      iconActive: <CardTransfer />,
      isSpecial: true
    },
    // Điều kiện hiển thị component "Bảo hiểm"
    isInsurance
      ? {
        title: t('Insurance'),
        path: routes.insurance.path,
        icon: <img src={APP_IMAGES.InsuranceLogo} alt="" height={32} />,
        iconActive: <img src={APP_IMAGES.InsuranceLogo} alt="" height={32} />,
        className: 'footer_item_copybet'
      }
      // Điều kiện hiển thị component "Copy bet"
      : iscopyBet
        ? {
          title: t('Copy_bet'),
          path: routes.copyBet.path,
          icon: <NavFactory />,
          iconActive: <NavFactoryBold />,
          className: 'footer_item_copybet'
        }// Điều kiện hiển thị component thông báo
        : {
          title: t('notification'),
          path: routes.notificationPersional.path,
          icon: <IconNotificationBell />,
          iconActive: <IconNotificationBell />,
          className: 'footer_item_copybet'
        },
    {
      title: t('Personal'),
      path: routes.managementProfile.path,
      icon: <NavUser />,
      iconActive: <NavUserBold />,
      className: 'footer_item_personal'
    }
  ]


  const listCTA = [
    {
      title: t('History'),
      icon: <IconHistory />,
      path: routes.historyDepositWithdraw.path,
      color: 'danger'
    },
    {
      title: t('Deposit_money'),
      path: routes.depositAuto.path,
      icon: <IconCardSend />,
      color: 'success'
    },
    {
      title: t('Withdraw_money'),
      path: routes.withdrawAuto.path,
      icon: <IconCardRecive />,
      color: 'primary'
    }
  ]

  return (
    <footer id="Footer" className="max_width_screen_project">
      <div className="footer_container">
        {listNav.map((item) => {
          const active = window.location.pathname === item.path
          return (
            <div
              key={item.title}
              className={classNames({
                footer_item: !item.isSpecial,
                footer_item_special: item.isSpecial,
                footer_item_active: active && !item.isSpecial,
                [item.className]: item.className
              })}
              onClick={() => {
                (item?.action)
                  ? (item?.action)()
                  : directRoute(item?.path)
              }}>
              <div
                style={{ position: 'absolute' }}
                className={classNames({
                  footer_item_activeLine: active && !item.isSpecial
                })}
              />
              <div className={classNames({
                footer_item_container: !item.isSpecial
              })}>
                <div
                  className={classNames({
                    footer_item_icon: !item.isSpecial,
                    footer_item_icon_special: item.isSpecial
                  })}>
                  {active ? item.iconActive : item.icon}
                </div>
                <p>{item.title}</p>
              </div>
            </div>
          )
        })}
      </div>
      <div className='footer_container_image' style={{ backgroundImage: `url(${APP_IMAGES?.BackgroundCard})` }} />
      <div className='footer_container_background' />
      {isSpecial && <div className='footer_blur_cta' onClick={() => setIsSpecial(!isSpecial)}>
        <div className='footer_blur_cta_container'>
          {
            listCTA.map((item) =>
              <BasicButton
                onClick={(e) => {
                  e.stopPropagation()
                  directRoute(item?.path)
                }}
                color={item?.color}
                className='footer_blur_cta_container_button'
              >
                {item?.icon}
                <span>{item?.title}</span>
              </BasicButton>
            )
          }
        </div>
      </div>}
    </footer>
  )
}
