import React, { useEffect, useMemo, useRef, useState } from 'react'
import Loader from './../../components/Loader'
import { Form } from 'antd'
import LoginService from './../../services/loginService'
import './forgotPass.scss'
import { injectIntl } from 'react-intl'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import { Header } from 'components/Layout/Header/Header'
import BasicButton from 'components/Basic/BasicButton/BasicButton'
import moment from 'moment'
import PopupValidatorPassword from 'components/Advanced/PopupValidatorPassword/PopupValidatorPassword'
import useCommonHook from 'hooks/useCommonHook'
import BasicInput from 'components/Basic/BasicInput/BasicInput'
import BasicSelect from 'components/Basic/BasicSelect/BasicSelect'
import AppUsers from 'services/apppUsers'
import { OTP_TYPE } from 'constants/config'
import { useUser } from 'context/UserContext'

const TIME_COUNTDOWN = 300

function ForgotPass(props) {
  const [isVisible, setIsVisible] = useState(false)
  const { user } = useUser()
  const { history } = props
  const { translate } = useCommonHook()
  const [otpCountdown, setOtpCountdown] = useState(0)
  const idCountdown = useRef(null)
  const [showPopup, setShowPopup] = useState(false)
  const [typeOfPassword, setTypeOfPassword] = useState('password')
  const [usernameForget, setUsernameForget] = useState(undefined)
  const [tokenForget, setTokenForget] = useState(undefined)
  const [errorsMessage, setErrorsMessage] = useState({
    newPassword: '',
    repassword: '',
    verification: '',
    email: ''
  })
  const [dataToAction, setDataToAction] = useState({
    newPassword: '',
    repassword: '',
    verification: '',
    email: ''
  })
  const [loading, setLoading] = useState(false)

  function onFinish() {
    if (!usernameForget) {
      handleConfirmOTP()
      return
    }

    if (
      !dataToAction.newPassword ||
      !dataToAction.repassword ||
      !dataToAction.verification ||
      !dataToAction.email
    ) {
      BasicAlertPopup({
        title: translate('forgotFail'),
        color: 'danger',
        icon: 'danger',
        content: translate('registerCheckValues')
      })
    } else {
      checkRegexText(dataToAction)
      handleForgotPass(dataToAction)
    }
  }

  function handleSendOTP() {
    let value = {
      email: dataToAction.email,
      otpType: typeOfPassword === 'password' ? OTP_TYPE.FORGOT_PASSWORD : OTP_TYPE.FORGOT_SECONDARY_PASSWORD
    }
    LoginService.userRequestEmailOTP(value).then((res) => {
      const { error } = res
      if (error) {
        handleCheckErrorOTP(error)
        clearTimeout(idCountdown.current)
        setOtpCountdown(0)
        return
      } else {
        BasicAlertPopup({
          title: translate('sendOtpSuccess'),
          color: 'success',
          icon: 'success',
          showCloseButton: true
        })
        return
      }
    })
  }
  const handleGoToLoginPage = () => {
    history.goBack()
  }

  const handleForgotPass = (values) => {
    if (loading) {
      return
    }
    if (
      errorsMessage.newPassword ||
      errorsMessage.repassword ||
      !dataToAction.newPassword ||
      !dataToAction.repassword
    ) {
      return
    } else {
      setIsVisible(true)
      const newData = {
        password: values.newPassword
      }
      const header = !user?.token
        ? { authorization: `Bearer ${tokenForget}` }
        : {}
      const apiFunc = typeOfPassword === 'secondaryPassword' ? AppUsers.resetSecondaryPasswordByToken : AppUsers.resetPasswordByToken
      setLoading(true)
      apiFunc(newData, header).then((result) => {
        setLoading(false)
        const { isSuccess, error } = result
        setIsVisible(false)
        if (!isSuccess) {
          handlCheckErrorForgot(error)
        } else {
          BasicAlertPopup({
            color: 'success',
            icon: 'success',
            content: translate('Password_changed_successfully'),
            confirmButtonText: translate('confirm'),
            onConfirm: handleGoToLoginPage
          })
        }
      })
    }
  }
  const handlCheckErrorForgot = (error) => {
    if (error === 'USER_NOT_FOUND') {
      setErrorsMessage({
        ...errorsMessage,
        username: translate('EMAIL_NOT_MATCH')
      })
    } else if (error === 'OTP_FAILED') {
      BasicAlertPopup({
        title: translate('OTP_FAILED'),
        color: 'danger',
        icon: 'danger',
        content: translate('registerCheckValues')
      })
    } else if (error === 'OTP_EXPIRED') {
      BasicAlertPopup({
        title: translate('OTP_EXPIRED'),
        color: 'danger',
        icon: 'danger',
        content: translate('registerCheckValues')
      })
    } else if (error === 'UPDATE_FAILED') {
      BasicAlertPopup({
        title: translate('forgotFail'),
        color: 'danger',
        icon: 'danger',
        content: translate('registerCheckValues')
      })
    } else if (error === 'UNKNOWN_ERROR') {
      BasicAlertPopup({
        title: translate('forgotFail'),
        color: 'danger',
        icon: 'danger',
        content: translate('registerCheckValues')
      })
    }else if (error === "MAINTAIN_FORGOT_PASSWORD" || error === "MAINTAIN_ALL") {
      BasicAlertPopup({
        title: translate("forgotFail"),
        color: "danger",
        icon: "danger",
        content: translate("MAINTAIN_FORGOT_PASSWORD"),
      })
    } else {
      BasicAlertPopup({
        title: translate('forgotFail'),
        color: 'danger',
        icon: 'danger',
        content: translate('registerCheckValues')
      })
    }
  }
  const errorPopup = (value) => {
    BasicAlertPopup({
      title: translate(value),
      color: 'danger',
      icon: 'danger',
      content: translate('registerCheckValues'),
      showCloseButton: true
    })
  }
  const handleCheckErrorOTP = (error) => {
    if (error == 'EMAIL_NOT_MATCH') {
      setErrorsMessage({
        ...errorsMessage,
        email: translate('EMAIL_NOT_MATCH')
      })
      return
    }
    if (error == 'USER_NOT_FOUND') {
      return setErrorsMessage({
        ...errorsMessage,
        email: translate('Email_does_not_exist_please_check_again')
      })
    }
    if (error == 'INVALID EMAIL') {
      setErrorsMessage({
        ...errorsMessage,
        email: translate('INVALID_EMAIL')
      })
      return
    }
    if (error == 'NOT_CONFIRMED_YET') {
      return BasicAlertPopup({
        content: translate('NOT_CONFIRMED_YET'),
        color: 'warning',
        icon: 'warning',
        confirmButtonText: translate('confirm'),
        confirmButtonColor: "primary"
      })
    }
    if (error == 'failed' || error == 'API_FAILED') {
      setErrorsMessage({
        ...errorsMessage,
        email: translate('Email_does_not_exist_please_check_again')
      })
      return
    }
    errorPopup('SEND_OTP_MAIL_FAILED')
    return
  }

  function handleConfirmOTP(params) {
    if (loading) {
      return
    }
    let value = {
      email: dataToAction.email,
      otp: dataToAction.verification
    }
    const apiUrl = user?.token ? LoginService.userConfirmEmailOTP : LoginService.confirmEmailOTPForgotPassword
    setLoading(true)
    apiUrl(value).then((res) => {
      setLoading(false)
      const { error, data } = res
      if (error) {
        if (error === "MAINTAIN_FORGOT_PASSWORD" || error === "MAINTAIN_ALL") {
          BasicAlertPopup({
            title: translate("Fail"),
            color: "danger",
            icon: "danger",
            content: translate("MAINTAIN_FORGOT_PASSWORD"),
            showCloseButton: true,
          })
        }
        setErrorsMessage({
          ...errorsMessage,
          verification: translate('invalidOTP')
        })
      } else {
        setUsernameForget(data?.username || user?.username)
        setTokenForget(data?.token)
      }
    })
  }

  function checkRegexPassText(values) {
    const number = new RegExp('(?=.*[0-9])')
    if (values.newPassword) {
      if (values?.newPassword.length < 6 || values?.newPassword.length > 20) {
        errorsMessage.newPassword = translate('Inappropriate_password_length')
        return
      }
      if (!number.test(values.newPassword)) {
        setErrorsMessage({
          ...errorsMessage,
          password: translate('passNumber')
        })
        return
      }
      // if (!values?.newPassword.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,20}$/)) {
      //   errorsMessage.newPassword = translate('format_pass')
      //   return
      // }
      errorsMessage.newPassword = ''
    }
  }
  function checkRegexSecondaryPassText(values) {
    if (values.newPassword) {
      if (values?.newPassword.length < 6 || values?.newPassword.length > 20) {
        errorsMessage.newPassword = translate('Inappropriate_password_length')
        return
      }
      errorsMessage.newPassword = ''
    }
  }
  function checkRegexRePassText(values) {
    if (values.repassword) {
      if (values?.newPassword !== values?.repassword) {
        setErrorsMessage({
          ...errorsMessage,
          repassword: translate('confirm_new_password_incorrect')
        })
      } else {
        setErrorsMessage({
          ...errorsMessage,
          repassword: ''
        })
      }
    }
  }
  function checkRegexEmailText(values, check) {
    if (values.email) {
      if (!values.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        setErrorsMessage({
          ...errorsMessage,
          email: translate('error_email')
        })
        return
      }
      setErrorsMessage({
        ...errorsMessage,
        email: ''
      })
    }
    setErrorsMessage({
      ...errorsMessage,
      email: ''
    })
    if (check) {
      handleSendOTP()
      setOtpCountdown(TIME_COUNTDOWN)
    }
  }
  function checkRegexVerificationText(values) {
    if (values.verification) {
      if (!values?.verification.match(/^[0-9]{6,}$/)) {
        setErrorsMessage({
          ...errorsMessage,
          verification: translate('error_verification')
        })
        return
      }
      setErrorsMessage({
        ...errorsMessage,
        verification: ''
      })
    }
  }
  const checkRegexText = (dataToAction) => {
    setTimeout(() => {
      typeOfPassword === 'secondaryPassword' ? checkRegexSecondaryPassText(dataToAction) : checkRegexPassText(dataToAction)
    }, 100)
  }
  useEffect(() => {
    checkRegexText(dataToAction)
  }, [dataToAction])

  useEffect(() => {
    if (otpCountdown !== 0) {
      idCountdown.current = setTimeout(() => {
        setOtpCountdown(otpCountdown - 1)
      }, 1000)
    }
  }, [otpCountdown])

  const optionsType = [
    {
      value: 'password',
      label: translate('loginPass')
    },
    {
      value: 'secondaryPassword',
      label: translate('transactionPass')
    }
  ]

  const isDisableActiveButton = useMemo(() => {
    if (!tokenForget && (!dataToAction.email || dataToAction.verification.length !== 6 || errorsMessage.email || errorsMessage.verification)) {
      return true
    }
    if (tokenForget && (!dataToAction.newPassword || !dataToAction.repassword || errorsMessage.newPassword || errorsMessage.repassword)) {
      return true
    }
    return false
  }, [dataToAction, errorsMessage, tokenForget])

  const isDisableSendOTP = useMemo(() => {
    if (!dataToAction.email || errorsMessage.email || otpCountdown !== 0) {
      return true
    }
    return false
  }, [dataToAction, errorsMessage, otpCountdown])

  return (
    <div className="forgot">
      <div className="forgot-header">
        <Header className="leader_header"
          title={typeOfPassword === 'secondaryPassword' ? translate('Recover_security_password') : translate('Recover_login_password')}
          iconsLeft={[{
            icon: 'back', onClick: () => {
              if (tokenForget) {
                setTokenForget(undefined);
                setUsernameForget(undefined);
                clearTimeout(idCountdown.current)
                setOtpCountdown(0)
                setDataToAction({})
                setErrorsMessage({})
              } else {
                history.goBack();
              }
            }
          }]}
        />
      </div>
      <div className="forgot-center">
        <Form
          className='forgot-form'
          name="login"
          onFinish={() => {
            onFinish()
          }}>
          {
            !usernameForget &&
            <>
              <BasicSelect
                value={optionsType.find((item) => item.value === typeOfPassword)}
                label={translate('Type')}
                placeholder={translate('Find_password_type')}
                disabled={otpCountdown !== 0}
                options={optionsType}
                onChange={(value) => {
                  setTypeOfPassword(value)
                  setDataToAction({ ...dataToAction, newPassword: '', repassword: '' })
                  setErrorsMessage({
                    ...errorsMessage,
                    newPassword: '',
                    repassword: ''
                  })
                }}
              />
              <BasicInput
                label={translate('Email_address')}
                className="input input-text-hidden hiden-border"
                controls={false}
                suffix={
                  <span
                    className={`buttonConfirm text-light ${isDisableSendOTP ? 'isDisable' : ''
                      }`}
                    onClick={() => {
                      checkRegexEmailText(dataToAction, true)
                    }}>
                    {!otpCountdown && translate('send_code')}
                    {otpCountdown === 0 ? '' : `${moment.utc(otpCountdown * 1000).format('mm:ss')}`}
                  </span>
                }
                placeholder={translate('inputEmail')}
                value={dataToAction.email}
                onBlur={() => checkRegexEmailText(dataToAction, false)}
                size="large"
                onChange={(e) => {
                  setErrorsMessage({
                    ...errorsMessage,
                    email: ''
                  })
                  const cleanedValue = e.target.value
                  setOtpCountdown(0)
                  clearTimeout(idCountdown.current)
                  setDataToAction({ ...dataToAction, email: cleanedValue })
                }}
                errorText={errorsMessage.email}
              />
              <BasicInput
                label={translate('verifyOTP')}
                className="input input-text-hidden inputBasic"
                type="text"
                placeholder={translate('inputOTP')}
                value={dataToAction?.verification}
                onBlur={() => checkRegexVerificationText(dataToAction)}
                onChange={(e) => {
                  setErrorsMessage({
                    ...errorsMessage,
                    verification: ''
                  })
                  const { value: inputValue } = e.target
                  const reg = /^\d*(\d*)?$/
                  if (reg.test(inputValue) || inputValue === '') {
                    if (e.target.value.length <= 6) {
                      setDataToAction({
                        ...dataToAction,
                        verification: e.target.value
                      })
                    }
                  }
                }}
                errorText={errorsMessage.verification}
              />
            </>
          }

          {
            usernameForget &&
            <>
              <BasicInput
                label={translate('account')}
                value={usernameForget}
                disabled
              />
              {
                typeOfPassword == 'password' && usernameForget &&
                <div>
                  <BasicInput
                    label={translate('new_password')}
                    className="input input-text-hidden hiden-border tracker"
                    type={'password'}
                    placeholder={translate('Enter_6_20_characters')}
                    size="large"
                    onBlur={() => setShowPopup(false)}
                    onClick={() => setShowPopup(true)}
                    onKeyDown={(e) => {
                      [' '].includes(e.key) && e.preventDefault()
                      setShowPopup(true)
                    }}
                    value={dataToAction.newPassword}
                    onChange={(e) => {
                      setDataToAction({ ...dataToAction, newPassword: e.target.value, repassword: '' })
                      checkRegexPassText({ ...dataToAction, newPassword: e.target.value })
                      checkRegexRePassText(dataToAction)
                      setErrorsMessage({
                        ...errorsMessage,
                        repassword: ''
                      })
                    }}
                    errorText={errorsMessage.newPassword}
                  />
                  {showPopup && (
                    <PopupValidatorPassword translate={translate} className={'tracker-box'} value={dataToAction.newPassword}></PopupValidatorPassword>
                  )}
                </div>
              }
              {
                typeOfPassword === 'secondaryPassword' && usernameForget &&
                <div>
                  <BasicInput
                    label={translate('new_password')}
                    className="input input-text-hidden hiden-border tracker"
                    type={'password'}
                    placeholder={translate('Enter_6_20_characters')}
                    size="large"
                    onKeyDown={(e) => {
                      [' '].includes(e.key) && e.preventDefault()
                      setShowPopup(true)
                    }}
                    value={dataToAction.newPassword}
                    onChange={(e) => {
                      setDataToAction({ ...dataToAction, newPassword: e.target.value, repassword: '' })
                      checkRegexSecondaryPassText({ ...dataToAction, newPassword: e.target.value })
                      checkRegexRePassText(dataToAction)
                      setErrorsMessage({
                        ...errorsMessage,
                        repassword: ''
                      })
                    }}
                    errorText={errorsMessage.newPassword}
                  />
                </div>
              }
              <div className="forgotItem">
                <BasicInput
                  label={translate('confirm_new_password')}
                  className="input input-text-hidden hiden-border"
                  type={'password'}
                  placeholder={translate('confirm_password')}
                  size="large"
                  value={dataToAction.repassword}
                  onKeyDown={(e) => [' '].includes(e.key) && e.preventDefault()}
                  onBlur={() => checkRegexRePassText(dataToAction)}
                  onChange={(e) => {
                    setErrorsMessage({
                      ...errorsMessage,
                      repassword: ''
                    })
                    setDataToAction({
                      ...dataToAction,
                      repassword: e.target.value
                    })
                    checkRegexPassText(dataToAction)
                  }}
                  errorText={errorsMessage.repassword}
                />
              </div>
            </>
          }
          <BasicButton className={`forgot-btn w-100
            ${isDisableActiveButton ? 'isDisable' : ''}
            `} size="large" htmlType="submit" color="success">
            {translate('confirm')}
          </BasicButton>
        </Form>
      </div>
      {isVisible ? <Loader /> : null}
    </div>
  )
}
export default injectIntl(ForgotPass)
