import { LocalStorageManager } from "helper/localStorage"

export const HOST = LocalStorageManager.getItem('apiGatewayUrl') || process.env.REACT_APP_API_URL || 'https://api.google.com/'
export const API_GAME_URL = process.env.REACT_APP_API_GAME_URL
export const IMAGE_HOST = HOST + 'upload/'
export const THEME = process.env.REACT_APP_THEME || 'PITGAME'

export const SHOW_BUTTON_INSURANCE = process.env.REACT_APP_SHOW_BUTTON_INSURANCE || '0'
export const SHOW_BUTTON_COPY_BET = process.env.REACT_APP_SHOW_BUTTON_COPY_BET || '0'
export const ENABLE_OTP_REGISTER = process.env.REACT_APP_ENABLE_OTP_REGISTER
export const ENABLE_SCORE_HISTORY = process.env.REACT_APP_ENABLE_SCORE_HISTORY

