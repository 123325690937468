import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import { useUser } from 'context/UserContext'
import useCommonHook from 'hooks/useCommonHook'
import WalletService from 'services/walletService'
import AppUsers from 'services/apppUsers'
import { useDispatch } from 'react-redux'
import { handleUpdateDetail } from 'actions'
import useActionPopup from 'actionsFeatures/actionsPopup'
import { useState } from 'react'

export default function useGetPointGameToPointWallet() {
  const dispatch = useDispatch()
  const { user } = useUser()
  const { translate } = useCommonHook()
  const [isLoadingWallet, setIsLoadingWallet] = useState(false)
  const { showPopupExpiredSession } = useActionPopup()

  async function handleGetAllWalletToPoint(params) {
    setIsLoadingWallet(true)
    await WalletService.withdrawAllGameWallet(params).then((result) => {
      const { isSuccess } = result
      if (!isSuccess) {
        BasicAlertPopup({
          title: translate('Fail'),
          content: translate('exchange_external_fail'),
          color: 'danger',
          icon: 'danger',
          confirmButtonText: translate('confirm'),
        })
        setIsLoadingWallet(false)
        return
      } else {
        setTimeout(() => {
          AppUsers.getDetailUserById({
            id: user?.appUserId
          }).then((result) => {
            const { isSuccess, data } = result
            setIsLoadingWallet(false)
            if (isSuccess) {
              if (data?.appUserId === user?.appUserId) {
                dispatch(handleUpdateDetail(data))
                BasicAlertPopup({
                  title: translate('Success'),
                  content: translate('Game_balance_has_been_transferred_to_point_wallet'),
                  color: 'success',
                  icon: 'success',
                  confirmButtonText: translate('confirm'),
                })
              } else {
                showPopupExpiredSession()
              }
            } else {
              BasicAlertPopup({
                title: translate('Fail'),
                content: translate('Wallet_refresh_failed'),
                color: 'danger',
                icon: 'danger',
                confirmButtonText: translate('confirm'),
              })
            }
          })
        }, 1000);
      }
    })
  }
  return {
    handleGetAllWalletToPoint,
    isLoadingWallet
  }
}