import React from 'react'
import { Popover } from 'antd'

export default function BasicPopover({
  className = '',
  overlayClassName = '',
  children,
  content,
  ...props
}) {
  return (
    <Popover
      content={content}
      trigger="click"
      overlayClassName={overlayClassName}
      {...props}
    >
      {children}
    </Popover>
  )
}
