import React, { useEffect } from 'react'
import useReloadResource from '../../actionsFeatures/actionsLoadResource'
import { useDispatch } from 'react-redux'
import { setLoadResource } from 'actions/loadingResourceAction'
import Loading from 'components/Loading'
import { routes } from 'App'
import useAutoLogin from 'Page/AutoLogin/actions'

export function Splash() {
  const dispatch = useDispatch()
  const {
    checkValidAPIGateway,
    loadGameCategoryAndGameInfo,
    preloadStaticImage
  } = useReloadResource()
  const { handleLoginByToken } = useAutoLogin()

  async function handlePreloadData() {
    await checkValidAPIGateway()
    if (window.location.pathname === routes.autoLogin.path) {
      await handleLoginByToken()
      return;
    }

    setTimeout(() => {
      dispatch(setLoadResource(false))
    }, [7000])
    await loadGameCategoryAndGameInfo()
    dispatch(setLoadResource(false))

    preloadStaticImage()
  }

  useEffect(() => {
    handlePreloadData()
    return () => { }
  }, [])

  return (
    <Loading />
  )
}
