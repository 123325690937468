import { useEffect, useState } from 'react'
import PaymentMethod from 'services/paymentMethod'
import useCommonHook from './useCommonHook'
import BasicAlertPopup from 'components/Basic/BasicAlertPopup/BasicAlertPopup'
import { useSystem } from 'context/SystemContext'

export const MAX_PAYMENT_METHOD_OF_USER = 5

export const PAYMENT_METHOD_TYPE = {
  ATM_BANK: 1,
  USDT: 2,
  PIT: 3,
  ONLINE_BANK: 4, // Thanh toán trực tuyến
  MOMO: 5, // MOMO
  ZALOPAY: 6, // ZALOPAY
  VIETTELPAY: 7, // VIETTELPAY
}

export const PAYMENT_METHOD_USAGE = {
  ATM_BANK: 'ATM_BANK',
  PIT: 'PIT',
  USDT: 'USDT'
}

export const PAYMENT_METHOD_NETWORK = [
  {
    value: 'Tron (TRC20)',
    label: 'Tron (TRC20)'
  }
]

export const WITHDRAW_TRX_CATEGORY = {
  BANK: 'ATM/BANK',
  USDT: 'USDT',
  DIRECT_REWARD: 'DIRECT_REWARD', // cong tien truc tiep
}

export const PAYMENT_UNIT = {
  England: 'GBP', //Bảng Anh
  SouthKorea: 'KRW', //Hàn Quốc
  Thailand: 'THB', //Bath Thái
  Malaysia: 'MYR', //Ringgit Malaysia
  China: 'RMB', //Nhân dân tệ
  Vietnam: 'VND', //Việt Nam
  Indonesia: 'IDR', //Đồng rupiah Indonesia
  Philipin: 'PHP', //Philippine peso
  USDT: 'USDT',
  PIT: 'PIT'
}

export const PAYMENT_UNIT_ID = {
  GBP: 1, //Bảng Anh
  KRW: 2, //Hàn Quốc
  THB: 3, //Bath Thái
  MYR: 4, //Ringgit Malaysia
  CNY: 5, //Nhân dân tệ
  VND: 6, //Việt Nam
  IDR: 7, //Đồng rupiah Indonesia
  PHP: 8, //Philippine peso
  USDT: 9,
  PIT: 10,
}

const usePaymentMethods = (paymentMethodType = 'USDT') => {
  const { translate, directCustomerService } = useCommonHook()
  const { system } = useSystem()
  const [paymentMethods, setPaymentMethods] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    PaymentMethod.getList({
      filter: {
        paymentMethodType: PAYMENT_METHOD_TYPE?.[paymentMethodType]
      }
    }).then((response) => {
      setPaymentMethods(response?.data?.data || [])
      setLoading(false)
    })
  }, [paymentMethodType])

  const showPopupService = () => {
    const itemsTabShow = {
      USDT: {
        messageService: translate('usePaymentMethod.You_can_only_add_{x}_usdt_wallet_address._Contact_support_to_be_answered', {
          x: +system?.maxLimitedPaymentUSDT >= 10 ? system?.maxLimitedPaymentUSDT : `${system?.maxLimitedPaymentUSDT || 1}`
          // x: `0${MAX_PAYMENT_METHOD_OF_USER}`
        })
      },
      PIT: {
        messageService: translate('usePaymentMethod.You_can_only_add_{x}_pit_wallet_address._Contact_support_to_be_answered', {
          x: +system?.maxLimitedPaymentPIT >= 10 ? system?.maxLimitedPaymentPIT : `${system?.maxLimitedPaymentPIT || 1}`
          // x: `0${MAX_PAYMENT_METHOD_OF_USER}`
        })
      },
      ATM_BANK: {
        messageService: translate('usePaymentMethod.You_can_only_add_{x}_account_bank._Contact_support_to_be_answered', {
          x: +system?.maxLimitedPaymentBank >= 10 ? system?.maxLimitedPaymentBank : `${system?.maxLimitedPaymentBank || 1}`
          // x: `0${MAX_PAYMENT_METHOD_OF_USER}`
        })
      }
    }
    BasicAlertPopup({
      content: itemsTabShow?.[paymentMethodType]?.messageService,
      color: 'info',
      icon: 'service',
      confirmButtonText: translate('contact'),
      showCloseButton: true,
      onConfirm: () => { directCustomerService() }
    })
  }
  return { data: paymentMethods, setData: setPaymentMethods, loading, showPopupService }
}

export { usePaymentMethods }
