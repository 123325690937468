import { handleSetListCategory } from "actions"
import { handleSetListGame } from "actions"
import { APP_IMAGES } from "assets/images/AppImages"
import Axios from "axios"
import { LIST_DOMAIN_TO_CHECK } from "constants/domain"
import { checkUrlIsImageOrVideo } from "helper/common"
import { LocalStorageManager } from "helper/localStorage"
import { sendTelegramBot } from "helper/telegramBot"
import { useDispatch } from "react-redux"
import GameService from "services/gameService"

export default function useReloadResource() {
  const dispatch = useDispatch()
  async function loadGameCategoryAndGameInfo() {
    const LIMIT = 5
    const paramsFilter = {}
    let arrayData = []
    async function handleFetchData() {
      const data = await gameInfoFind(paramsFilter)
      if (!data) return
      if (Array.isArray(data)) {
        arrayData = [...arrayData, ...data]
        paramsFilter.skip += LIMIT
        if (data.length === LIMIT) {
          return await handleFetchData()
        }
      }
    }

    await gameCategoryFind()
    await handleFetchData()

    const listGameInfo = arrayData.sort((a, b) => (a.gameOrdinal == null) - (b.gameOrdinal == null) || (a.gameOrdinal - b.gameOrdinal))
    dispatch(handleSetListGame(listGameInfo))

    const listImage = listGameInfo?.map((item) => item.gameLogoUrl)
    await preloadImageAsync(listImage)

  }

  async function gameCategoryFind() {
    await GameService.GameCategoryFind({}).then(async (result) => {
      if (result.isSuccess) {
        const listGameCategory = result.data?.data?.sort((a, b) => (a.gameCategoryOrdinal == null) - (b.gameCategoryOrdinal == null) || (a.gameCategoryOrdinal - b.gameCategoryOrdinal))
        dispatch(handleSetListCategory(listGameCategory))

        const listImage = listGameCategory?.flatMap((item) => [item.gameCategoryLogoUrl, item.gameCategoryActiveLogoUrl])
        await preloadImageAsync(listImage)
      }
    })

  }

  async function gameInfoFind(params) {
    const data = await GameService.GameInfoFind(params).then((result) => {
      if (result.isSuccess) {
        return result.data?.data
      } else {
        return undefined
      }
    })
    return data
  }

  async function checkValidAPIGateway() {
    const mainDomain = process.env.REACT_APP_API_URL?.replace('https://', '').replace('http://', '')?.replace('/', '')
    const listDomain = [mainDomain, ...LIST_DOMAIN_TO_CHECK]

    let listDomainDisable = []

    for (const domain of listDomain) {
      try {
        const response = await Axios({
          method: 'POST',
          url: `https://${domain}/HealthCheck/check`,
          data: {},
          headers: {}
        })
        if (response.status === 200) {
          LocalStorageManager.setItem('apiGatewayUrl', `https://${domain}/`)
          break;
        } else {
          listDomainDisable.push(domain)
        }
      } catch (error) {
        listDomainDisable.push(domain)
      }
    }

    if (listDomainDisable.length > 0) {
      sendTelegramBot(`Domain disable: ${JSON.stringify(listDomainDisable)}`)
    }
  }

  async function preloadResources(url) {
    if (checkUrlIsImageOrVideo(url) === 'video') {
      return new Promise((resolve) => {
        const video = document.createElement('video');
        video.src = url;
        video.preload = 'auto'; // Tải video trước khi nó được phát

        video.onloadeddata = () => resolve(url);
        video.onerror = () => resolve(`Failed to load video: ${url}`);
      });
    } else {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;

        img.onload = () => resolve(url);
        img.onerror = () => resolve(`Failed to load image: ${url}`);
      });
    }
  }

  async function preloadStaticImage() {
    const images = Object.values(APP_IMAGES)
    for (const image of images) {
      preloadResources(image)
    }
  }

  async function preloadImageAsync(images) {
    const loadPromises = images.map(src => preloadResources(src))
    await Promise.all(loadPromises)
  }

  return {
    checkValidAPIGateway,
    loadGameCategoryAndGameInfo,
    preloadStaticImage
  }
}