import { useState } from "react";

const ModalLoginHooks = () => {
  const [isOpenModalLogin, setIsOpenModalLogin] = useState(false);

  return {
    isOpenModalLogin,
    setIsOpenModalLogin,
  }
}

export default ModalLoginHooks