import { routes } from "App"
import BasicAlertPopup from "components/Basic/BasicAlertPopup/BasicAlertPopup"
import { LocalStorageManager } from "helper/localStorage"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { removeLocalStorageLogout } from "reducers/member"

export default function useActionPopup() {
  const intl = useIntl()
  //dùng ở common hook sẽ lỗi
  const translate = useCallback((id, options = {}) => id && intl.formatMessage({ id }, options), [intl])

  const showPopupExpiredSession = () => {
    BasicAlertPopup({
      title: translate('Session_expired_popup_title'),
      content: translate('Session_expired_popup_content'),
      color: 'primary',
      icon: 'warning',
      confirmButtonText: translate('Confirm'),
      onConfirm: () => {
        removeLocalStorageLogout()
        setTimeout(() => {
          window.location.href = routes.home.path
        }, 200);
      }
    })

  }
  return {
    showPopupExpiredSession
  }
}