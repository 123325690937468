import { combineReducers } from 'redux'
import app from './app'
import member from './member'
import gameInfo from './gameInfo'
import loadingResource from './loadingResource'

const rootReducer = combineReducers({
  member,
  app,
  gameInfo,
  loadingResource
})

export default rootReducer
